import React from 'react';

import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { shuffle } from "lodash";

const spring = {
  type: "spring",
  damping: 20,
  stiffness: 300, 
  velocity:20
};

const colorWidths = [21, 55, 8, 34];
const initialColors = ["#F02E1E", "#304269", "#4BC2A5", "#D9E8F5"];

const HeaderAni = () => {
  const [colors, setColors] = useState(initialColors);

  useEffect(
    () => {
      let timer = setTimeout(() => setColors(shuffle(colors)), 8000);
      return () => {
        clearTimeout(timer);
      }
  }, [colors]);


  
  return (
    <ul className="header-ani">
      {
      
      colors.map((background, index ) => {
        var randomWidthIndex = Math.floor(Math.random() * colorWidths.length);

        const divStyle = {
          width: colorWidths[randomWidthIndex] + "%", 
          background: background
        }

          return <motion.li
          key={background}
          layoutTransition={spring}
          style={{ background:divStyle.background, width:divStyle.width }}
        />;
      }
        
      )}
    </ul>
  );
};

export default HeaderAni;


