
import React, { Component } from 'react'

// import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image"


export class Articles extends Component {
    constructor(props){
        super(props);


        this.state = {
            showContent: null   
        };
    }

    handleClick = () => {

        if(this.state.showContent !== this.props.onItemClick){
          this.setState({
            showContent: this.props.onItemClick
          });
        }else{
            this.setState({
                showContent: false
              });
        }
      
    }

    render() {
        const post = this.props.post.node;

        const imageData = post.featured_media.localFile.childImageSharp.fluid;
        return (
            <article onClick={this.handleClick} key={post.slug} className="mb4 w-100 fl mh_post">
            <div className="mh_color_header post-bg-color"> 
            </div>

            <div className="mh_project_pane mh_mw center"> 

              <div className="mh_project_header pa3">
                <p className="mh_font_clr f3">{post.title}</p>
                <div className={`circle ${this.state.showContent === post.slug && this.state.showContent !== false? "active" : " "} ${this.state.showContent === false? "reverse" : " "}`}></div>
              </div>
              <div className={`mh_project_display bg-white pa3-ns  ${this.state.showContent === post.slug && this.state.showContent !== false? "active" : " "}`}>
              <Img
                Tag="div"
                objectPosition={`center top`}
                className="hp-img bg-center"
                fluid={imageData}
                backgroundColor={`#040e18`}
              />
              </div>
              <div className={this.state.showContent === post.slug ? "mh_project_details active" : "mh_project_details"}> 
              <section className="pa3"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: post.content,
                }}
              /> 
            </div>
            </div>
          
          </article>
        )
    };
}

export default Articles
