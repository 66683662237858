import React from 'react';

const Hero = () => (
  <div className="intro pa3-m pa3 pa0-l pb5 pb5-m  pb6-l mh_font_clr">
    <div className="mh_mw center mh-hero-section">
      <h1 className="f-subheadline-ns mb4 lh-title">Hi, I’m Mike.</h1>
      <div className="dek lh-title">
        <h3>Front-end developer and graphic designer.</h3>
      </div>
      <div className="f5 lh-copy mv3">
        <p>I create cool user interfaces for responsive web sites and JavaScript web applications.</p>
        <p>I build web technologies in multiple tools including, but not limited to, React, Angular, WordPress, Drupal, Sitecore and SCA. </p>
        <p>Nice to meet you!</p>
      </div>
      <ul>
        <li><a className="link" href="https://www.linkedin.com/in/michael-hudson-6215758/">Linked In</a></li>
        <li><a className="link" href="mailto:&#109;&#105;&#099;&#104;&#097;&#101;&#108;&#046;&#104;&#101;&#110;&#114;&#121;&#107;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;">Email Me</a></li>
      </ul>
    </div>

  </div>
);

export default Hero;
