import React from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Articles from "../components/Articles"

import Header from "../components/header"
import HeaderAni from "../components/HeaderAni"
import Footer from "../components/Footer"
import Hero from "../components/Hero"

const IndexPage = ({data}) => (
  <Layout key="index">
    <SEO title="Home" />
    <HeaderAni></HeaderAni>
    <Header />
    <Hero />
    <div className="post_list">
      {data.allWordpressPost.edges.map((node)=> (
        <Articles post={node} onItemClick={node.node.slug} key={node.node.slug}></Articles>
      ))}
    </div>   
    <Footer></Footer>
  </Layout>
)

export const query = graphql`
    query {
      allWordpressPost {
        edges {
          node {
            id
            title
            link
            content
            slug
            featured_media {
              source_url
              localFile {
                childImageSharp {
                  fluid(maxWidth:1250, quality: 100, srcSetBreakpoints:[1250, 800, 400]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }       
`;
export default IndexPage
