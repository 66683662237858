import React from 'react';

const Footer = () => (
  <div className="footer">
    <p className="white">Michael Hudson. Front-end Developer. hudsonandtask LLC</p>
    <a href="/"className="logo">
        <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="mhudson_logo" fill="#304269" fillRule="nonzero">
            <path d="M31.2158025,12.677037 C31.0316049,11.4898765 29.3945679,10.3940741 29.3945679,10.3940741 C29.3945679,10.3940741 26.6755556,17.1535802 25.962963,18.9101235 L16.2108642,18.9101235 L21.3328395,5.87654321 L18.7066667,5.86567901 L13.4380247,18.9101235 L5.82419753,18.9101235 L5.82419753,21.357037 L12.6162963,21.357037 L8.11308642,32.7802469 C4.50765432,29.4681481 2.44691358,24.8004938 2.44691358,19.9175309 C2.44691358,10.2839506 10.2844444,2.44691358 19.9180247,2.44691358 C29.5511111,2.44691358 37.388642,10.2839506 37.388642,19.9175309 C37.388642,29.5511111 29.5511111,37.388642 19.9180247,37.388642 C16.4054321,37.388642 13.0207407,36.3451852 10.1165432,34.3683951 L15.2464198,21.357037 L24.9792593,21.357037 L24.0602469,23.677037 C23.16,25.9565432 22.6661728,27.2128395 22.4666667,27.7545679 C21.7496296,29.7046914 22.5950617,31.7585185 24.3911111,32.428642 C24.7501235,32.5634568 25.1279012,32.6316049 25.5353086,32.6316049 L25.5358025,32.6316049 C27.08,32.6222222 29.2804938,31.5614815 30.402963,28.6044444 C30.4711111,28.4241975 30.108642,27.9446914 29.5916049,27.7437037 C29.037037,27.5279012 28.3214815,27.6014815 28.2735802,27.7397531 C27.6009877,29.6780247 26.2138272,30.3822222 25.397037,30.0918519 C25.1382716,30.0004938 24.9091358,29.7871605 24.7837037,29.5204938 C24.6918519,29.3249383 24.6133333,29.0064198 24.762963,28.5995062 C25.12,27.628642 25.5249383,26.6128395 26.037037,25.3259259 L27.6123457,21.357037 L33.5950617,21.357037 L33.5950617,18.9101235 L28.5995062,18.9101235 L31.2158025,12.677037 Z M19.9180247,0 C8.93530864,0 0,8.93481481 0,19.9175309 C0,30.8997531 8.93530864,39.8350617 19.9180247,39.8350617 C30.9002469,39.8350617 39.8355556,30.8997531 39.8355556,19.9175309 C39.8355556,8.93481481 30.9002469,0 19.9180247,0 L19.9180247,0 Z" id="Fill-52"></path>
          </g>
        </g>
        </svg>
     </a>
  </div>
);

export default Footer;
